import React from 'react';
import Header from './Header';
import MainContent from './MainContent';
import Footer from './ Footer';
import HomePage from './HomePage'
import PrivacyPolicy from './PrivacyPolicy'
import TermsOfUse from './TermsOfUse'
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

function App() {
  return (
    <BrowserRouter>
    <div className="App">
      <div id="root">
      <Header/>
      <div className="wrapper">
      <Routes>
            <Route path="/" element={<HomePage />} />  {/* Home Page at root */}
            <Route path="/calculator" element={<MainContent />} />  {/* MainContent at '/details' */}
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />  {/* MainContent at '/details' */}
            <Route path="/terms-of-use" element={<TermsOfUse />} />  {/* MainContent at '/details' */}
          </Routes>
      </div>
      <Footer/>
      </div>
    </div>
    </BrowserRouter>
  );
}

export default App;
