import React, { useEffect, useRef, useState } from 'react';
import './MainContent.css'; // Make sure to create MainContent.css
import { SolarSummary } from './SolarSummary';
import { CostComparisonGraph } from './CostComparisonGraph';
import { Calculator } from './Calculator';
import {SolarSummaryProps, CostComparisonGraphProps} from './SolarSummaryProps'

const MainContent = () => {
  const locationInputRef = useRef<HTMLInputElement>(null);
  let autocomplete: google.maps.places.Autocomplete;

  useEffect(() => {
    if (!locationInputRef.current) return;
  
    const handlePlaceSelect = () => {
      const place = autocomplete.getPlace();
      // Check if place has a formatted address before setting it
      if (place.geometry && place.formatted_address) {
        setLocation(place.formatted_address); // Only set location if formatted_address is not undefined
      } else {
        console.log('No address returned from autocomplete');
        setLocation(''); // Optionally clear location or handle as needed
      }
    };

    autocomplete = new google.maps.places.Autocomplete(locationInputRef.current, {         types: ['geocode'],
    componentRestrictions: { country: 'in' }  // Restricting results to India
 });
    autocomplete.addListener('place_changed', handlePlaceSelect);
  
    return () => {
      // Cleanup listener on component unmount
      google.maps.event.clearInstanceListeners(autocomplete);
    };
  }, []);

  const [location, setLocation] = useState('');
  const [monthlyBill, setMonthlyBill] = useState('');
  const [propertyType, setPropertyType] = useState('');
  const [results, setResults] = useState<SolarSummaryProps | null>(null);
  const [costComparisonGraph, setcostComparisonGraph] = useState<CostComparisonGraphProps | null>(null);

  const handleLocationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLocation(event.target.value);
  };

  const handleMonthlyBillChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMonthlyBill(event.target.value);
  };

  const handlePropertyTypeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setPropertyType(event.target.value);
  };

  const handleCalculateSavingsClick = async () => {
    const monthlyBillNumber = Number(monthlyBill);
    const calculator = new Calculator();
    const results: SolarSummaryProps  = calculator.calculateSolarRequirements(monthlyBillNumber, propertyType);
    const costComparisonGraphData: CostComparisonGraphProps  = calculator.calculategraphs(monthlyBillNumber, results.finalPrice, results.billWithSolar);
    setResults(results)
    setcostComparisonGraph(costComparisonGraphData)

    console.log(`Area Required: ${results.areaRequired} square feet`);
    console.log(`Cost: $${results.price}`);
  };

  return (
    <main className='wrapper2'>
    <div className={`main-content ${results ? 'wide' : 'narrow'}`}>
      <h1>Discover your solar savings potential</h1>
      {!results && <div className="chill">
        <input type="text" placeholder="Enter your location"
          ref={locationInputRef}
          value={location}
          onChange={handleLocationChange} />
        <div className="flex-row">
          <input type="number" placeholder="Enter your monthly bill"
            value={monthlyBill}
            onChange={handleMonthlyBillChange}
          />
          <select name="propertyType" value={propertyType}
            onChange={handlePropertyTypeChange}>
            <option value="" disabled>Select property type</option>
            <option value="residential">Residential</option>
          </select>
        </div>

        <button className="generate-report" onClick={handleCalculateSavingsClick}>Calculate Savings</button>
      </div>}
      {(costComparisonGraph && results) && (
        <div className="chill">
          <div className="dynamic-top-row">
            <input type="text" placeholder="Enter your location"
              ref={locationInputRef}
              value={location}
              onChange={handleLocationChange} />
            <input type="number" placeholder="Enter your monthly bill"
              value={monthlyBill}
              onChange={handleMonthlyBillChange} />
            <select name="propertyType" value={propertyType}
              onChange={handlePropertyTypeChange}>
              <option value="" disabled>Select property type</option>
              <option value="residential">Residential</option>
            </select>
            <button className="generate-report" onClick={handleCalculateSavingsClick}>Calculate Savings</button>
          </div>
          <div className="results-container">
            <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
              <SolarSummary
                summary={results}
              />
            </div>
            <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
              <CostComparisonGraph 
              data = {costComparisonGraph}
              />
            </div>
          </div>
        </div>
      )}
      </div>
    </main>
  );

};

export default MainContent;
