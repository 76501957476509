import React from 'react';
import './Header.css'; // Make sure to create Header.css
import { Link } from 'react-router-dom';
const Header = () => {
  return (
    <header className="header">
      <div className="navigation"><Link to="/">Home</Link></div>
      <div className="navigation">
        <Link to="/calculator">Solar Savings Calculator</Link>
      </div>
    </header>
  );
};

export default Header;
