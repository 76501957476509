import React from 'react';
import './Footer.css'; // Make sure to create Footer.css
import { Link } from 'react-router-dom';


const Footer = () => {
  return (
    <footer className="footer">
      <div className="navigation">
        <Link to="/privacy-policy">Privacy Policy</Link>
      </div>
      <div className="navigation">
        <Link to="/terms-of-use">Terms of Service</Link>
      </div>
      <a href="#">Contact Us</a>
      <span>SolarIQ 2024. All rights reserved.</span>
    </footer>
  );
};

export default Footer;
