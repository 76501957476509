import React from 'react';
import './PrivacyPoilcy.css'; // Make sure to create Footer.css

const PrivacyPolicy: React.FC = () => {
    return (
        <div className="privacy-policy-container">
            <h1>Privacy Policy</h1>
            <p>Last Updated: May 5, 2024</p>

            <h2>Our Commitment to Your Privacy</h2>
            <p>
                At SolarIQ, your privacy is important to us. This Privacy Policy explains what information we collect from you, how we use it, store it, protect it, and the conditions under which we may share it. We encourage you to read this document carefully to understand our practices and your options. For the latest updates, please visit our website.
            </p>

            <h2>Accepting the Policy</h2>
            <p>
                By using our site, you accept the terms of this policy and our Terms of Use. We may update this policy without notice, so please review it periodically. Continued use of our website after changes means you accept those changes. This policy does not cover other companies not owned or controlled by SolarIQ.
            </p>

            <h2>Information We Collect</h2>
            <p>
                You don’t need to provide personal information just to explore our site. To register or use services, you’ll need to provide personal details. Information about your property that doesn’t personally identify you isn’t considered personal information.
            </p>

            <h2>How We Use Your Information</h2>
            <p>
                We use your data to enhance our site, share relevant information with partners, and develop new services. We analyze usage patterns to tailor content and marketing to your needs. If you prefer not to be contacted for marketing, you can opt-out.
            </p>

            <h2>Sharing Your Information</h2>
            <p>
                We share your information with partners as needed to provide services or when legally required. Public posts can be viewed by others. We are not responsible for how others use information posted in public areas of our site.
            </p>

            <h2>Protecting Your Information</h2>
            <p>
                We take reasonable steps to protect your information but cannot guarantee its absolute security. Keep your account details and passwords private. Log out after sessions to protect your privacy.
            </p>

            <h2>Correcting Your Information</h2>
            <p>
                You can update or delete your personal information anytime through your account settings. Deletion might not remove all your data from our systems due to technical limitations.
            </p>

            <h2>Policy on Discrimination</h2>
            <p>
                We comply with Indian laws against discrimination on any basis, ensuring fairness and equality in our services.
            </p>

            <h2>Changes and Questions</h2>
            <p>
                Our Privacy Policy may change over time. We will post updates on our site but won’t notify you separately. Check our website for the latest version. If you have questions, email us.
            </p>
        </div>
    );
}

export default PrivacyPolicy;
