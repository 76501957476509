import React from 'react';
import './SolarSummary.css';
import {SolarSummaryProps} from './SolarSummaryProps'
export const SolarSummary: React.FC<{summary: SolarSummaryProps}> = ({ summary }) => {
  return (
    <table className="solar-summary-table">
      <tbody>
        <tr>
          <th>Recommended System Size</th>
          <td>{summary.systemSize.toFixed(1)}kW</td>
          <td>Area Required {Math.round(summary.areaRequired)} Sqft</td>
        </tr>
        <tr>
          <th>Your Monthly Bill</th>
          <td>Current Bill: ₹{Math.round(summary.currentBill).toLocaleString('en-IN')}</td>
          <td>Bill With Solar: ₹{Math.round(summary.billWithSolar).toLocaleString('en-IN')}</td>
        </tr>
        <tr>
          <th>Estimated Cost</th>
          <td>₹{Math.round(summary.price).toLocaleString('en-IN')}</td>
          <td>Subsidy: ₹{Math.round(summary.subsidy).toLocaleString('en-IN')}</td>
        </tr>
        <tr>
          <th>Final Price</th>
          <td colSpan={2}>₹{Math.round(summary.finalPrice).toLocaleString('en-IN')} after subsidy</td>
        </tr>
        <tr>
          <th>Lifetime Savings</th>
          <td colSpan={2}>₹{Math.round(summary.lifetimeSavings).toLocaleString('en-IN')}</td>
        </tr>
        <tr>
          <th>Impact on Environment</th>
          <td>{Math.round(summary.treesAdded).toLocaleString('en-IN')} Trees Added</td>
          <td>{Math.round(summary.carsRemoved).toLocaleString('en-IN')} Cars Removed</td>
        </tr>
      </tbody>
    </table>
  );
};
