import React from 'react';
import './HomePage.css';

const SolarPage = () => {
  return (
    <div className="solar-page">
      <section className="hero">
        <h1>Switch to Solar Energy and Save Up to 90% on Your Electricity Bills</h1>
        <button>Get a free quote</button>
      </section>

      <section className="benefits">
        <div className="benefit">
          <img src="/homepage/first.png" alt="Save Money" />
          <h2>Lower your electricity bills</h2>
          <p>With SunPower, you can save up to 90% on your electricity bills. Solar energy is cheaper than grid electricity in the long run, and you can benefit from government incentives and rebates.</p>
        </div>
        <div className="benefit">
          <img src="/homepage/second.png" alt="Earn Money" />
          <h2>Earn money with net metering</h2>
          <p>With net metering, you can earn money by selling excess solar energy back to the grid. This is a  great way to reduce your electricity bills and make a profit  from your solar panels.</p>
        </div>
        <div className="benefit">
          <img src="/homepage/third.png" alt="Energy Independence" />
          <h2>Reduce your carbon footprint</h2>
          <p>Solar energy is clean and renewable. By using solar power,  you can reduce your carbon footprint and help combat climate change. This is a great way to contribute to a more sustainable future for our planet.</p>
        </div>
        <div className="benefit">
          <img src="/homepage/fourth.png" alt="Protect the Environment" />
          <h2>Increase your property value</h2>
          <p>Solar panels can increase the resale value of your home. They are a long-term investment that can pay off when you sell your property. Homebuyers are willing to pay more for homes with solar panels, as they offer long-term cost savings and environmental benefits.</p>
        </div>
      </section>

      <section className="services">
        <div className="service">
        <img src="/homepage/one.png" alt="Protect the Environment" />
          <h2>Residential Solar</h2>
          <p>Switch to solar energy and save up to 90% on your electricity bills. Our residential solar  systems are designed to meet your energy needs and budget.</p>
        </div>
        <div className="service">
        <img src="/homepage/two.png" alt="Protect the Environment" />
          <h2>Commercial Solar</h2>
          <p>Reduce your business operating costs and carbon footprint with commercial solar. We offer turnkey solar solutions for businesses of all sizes.</p>
        </div>
        <div className="service">
        <img src="/homepage/three.png" alt="Protect the Environment" />
          <h2>Storage Solutions</h2>
          <p>Store excess solar energy and use it at night or during power outages. Our storage solutions help you maximize your energy savings and minimize your reliance on the grid.</p>
        </div>
      </section>

      {/* <section className="testimonials">
        <div className="testimonial">
          <p>"SunPower was professional, efficient, and easy to work with."</p>
        </div>
        <div className="testimonial">
          <p>"We're thrilled with our new solar system from SunPower."</p>
        </div>
      </section> */}
    </div>
  );
}

export default SolarPage;
