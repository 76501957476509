import {SolarSummaryProps, CostComparisonGraphProps, SipCalculatorParams} from './SolarSummaryProps'

export class Calculator {
    // Constructor for the Calculator class (if needed, you can expand it later)
    constructor() {}

    // Method to calculate solar panel requirements based on the monthly bill
    calculateSolarRequirements(monthlyBill: number, propertyType: string):  SolarSummaryProps {
        console.log(propertyType)
        let per_uint = 6
        const units = (monthlyBill*.8) / per_uint;
        const unitsPerDay = units / 30.42;
        const dailyKW = unitsPerDay / 4.8; // Assuming 4.8 hours off-grid per day
        const areaRequired = 101 * dailyKW;
        const cost = 65000 * dailyKW + 5000;
        let subsidy = 0;
        if(dailyKW<=2){
            subsidy=30000*dailyKW;
        }
        else if(dailyKW<=3){
            subsidy=(dailyKW-2)*18000+60000;
        }
        else{
            subsidy=78000;
        }
        return {
            systemSize: dailyKW,
            areaRequired: areaRequired,
            currentBill: monthlyBill,
            billWithSolar: monthlyBill*.15,
            price: cost,
            subsidy: subsidy,
            finalPrice: cost-subsidy,
            lifetimeSavings: (
                this.sipCalculator({lumpSum: 0, monthlyInvestment:monthlyBill, annualReturn:6, years:25})
                - this.sipCalculator({lumpSum: (cost-subsidy), monthlyInvestment:monthlyBill*.2, annualReturn:6, years:25})
            ),
            treesAdded: (unitsPerDay*365*25)/1000,
            carsRemoved: dailyKW
        };
    }


    sipCalculator = ({
        lumpSum,
        monthlyInvestment,
        annualReturn,
        years
    }: SipCalculatorParams): number => {
        const monthlyReturn = annualReturn / 12 / 100;
        const totalMonths = years * 12;
    
        let futureValueLumpSum = lumpSum * Math.pow((1 + monthlyReturn), totalMonths);
    
        let futureValueMonthlyInvestments = 0;
        for (let month = 1; month <= totalMonths; month++) {
            futureValueMonthlyInvestments += monthlyInvestment * Math.pow((1 + monthlyReturn), (totalMonths - month + 1));
        }
    
        const totalFutureValue = futureValueLumpSum + futureValueMonthlyInvestments;
    
        return totalFutureValue;
    };

    calculateEMI(loanAmount: number, downPayment: number, interestRate: number, numberOfMonths: number): number {
        const principal = loanAmount - downPayment;
        const monthlyInterestRate = interestRate / 12 / 100;
    
        if (monthlyInterestRate === 0) {
            return principal / numberOfMonths;
        } else {
            const emi = (principal * monthlyInterestRate * Math.pow(1 + monthlyInterestRate, numberOfMonths)) / (Math.pow(1 + monthlyInterestRate, numberOfMonths) - 1);
            return emi;
        }
    }
    

    calculategraphs(monthlyBill: number, totalCost: number, solarBill: number):  CostComparisonGraphProps {
        const traditionalCost = Array.from({ length: 10 }, (_, i) => ({
            year: i,
            cost: this.sipCalculator({lumpSum:0,monthlyInvestment:  monthlyBill, annualReturn:6, years:i})
        }));

        const solarUpfrontCost = Array.from({ length: 10 }, (_, i) => ({
            year: i,
            cost: this.sipCalculator({lumpSum: totalCost, monthlyInvestment:  solarBill, annualReturn:6, years:i})

        }));
        const emi = this.calculateEMI(totalCost, totalCost/3, 11, 36)
        const solarEMICostData = Array.from({ length: 10 }, (_, i) => ({
            year: i,
            cost: i<4?this.sipCalculator({lumpSum:totalCost/3, monthlyInvestment:  solarBill + emi, annualReturn:6, years:i}):
            this.sipCalculator({lumpSum: this.sipCalculator({lumpSum:totalCost/3, monthlyInvestment:  solarBill + emi, annualReturn:6, years:3}),
             monthlyInvestment: solarBill ,annualReturn: 6,years: i-3})
        }));

        return {
            traditionalCost,
            solarUpfrontCost,
            solarEMICostData
        };
    }
}
