import React from 'react';
import './CostComparisonGraph.css'; // Assuming you named your stylesheet this way
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { CostComparisonGraphProps} from './SolarSummaryProps'


export const CostComparisonGraph: React.FC<{data: CostComparisonGraphProps}> = ({ data }) => {
  return (
    <ResponsiveContainer width="100%" height={300}>
      <LineChart
        width={500}
        height={300}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3"  stroke="none" />
        <XAxis dataKey="year" allowDuplicatedCategory={false} />
        <YAxis />
        <Tooltip />
        <Legend />
        <Line type="monotone" dataKey="cost" data={data.traditionalCost} stroke="#8884d8" name="Traditional" />
        <Line type="monotone" dataKey="cost" data={data.solarUpfrontCost} stroke="#82ca9d" name="Solar Upfront" />
        <Line type="monotone" dataKey="cost" data={data.solarEMICostData} stroke="#ffc658" name="Solar EMI" />
      </LineChart>
    </ResponsiveContainer>
  );
};
