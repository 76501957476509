import React from 'react';
import './TermsOfUse.css'; // Import the corresponding CSS file

const TermsOfUse: React.FC = () => {
    return (
        <div className="terms-container">
            <h1>Terms of Use for SolarIQ</h1>
            <p>Effective Date: May 5th, 2024</p>

            <h2>1. Acceptance of Terms</h2>
            <p>By accessing and using the SolarIQ website, you agree to these Terms of Use and our Privacy Policy. If you do not agree with any part of these terms, you should discontinue use of the site immediately.</p>

            <h2>2. Changes to Terms</h2>
            <p>SolarIQ reserves the right to modify these terms at any time. We will post the revised terms on the site, and they will become effective once posted. Your continued use of the site after such changes will constitute your acceptance of the new terms.</p>

            <h2>3. Use of the Website</h2>
            <p>Permitted Use: You may use the site and its content for your personal, non-commercial use.</p>
            <p>Prohibited Use: You may not use the site to engage in any unlawful activity or fraudulent purposes or to infringe on the rights of SolarIQ, its users, or others.</p>

            <h2>4. Intellectual Property</h2>
            <p>All content on this site, including text, graphics, logos, and images, is the property of SolarIQ or its content suppliers and protected by international copyright and intellectual property laws.</p>

            <h2>5. User Content</h2>
            <p>If you post content or submit material, and unless we indicate otherwise, you grant SolarIQ a nonexclusive, royalty-free, perpetual, irrevocable right to use, reproduce, modify, adapt, publish, translate, create derivative works from, distribute, and display such content throughout the world in any media.</p>

            <h2>6. Privacy</h2>
            <p>Your privacy is important to us. Our Privacy Policy explains how we collect, use, and protect your information. Please read it carefully.</p>

            <h2>7. Disclaimer of Warranties</h2>
            <p>The SolarIQ site is provided on an "as is" and "as available" basis. SolarIQ makes no representations or warranties of any kind, express or implied, as to the operation of the site or the information, content, materials, or products included on it.</p>

            <h2>8. Limitation of Liability</h2>
            <p>SolarIQ will not be liable for any damages of any kind arising from the use of this site, including, but not limited to direct, indirect, incidental, punitive, and consequential damages.</p>

            <h2>9. Indemnification</h2>
            <p>You agree to indemnify and hold SolarIQ and its employees, harmless from any claims, actions, costs, liabilities, and expenses including legal fees arising from your use of the site.</p>

            <h2>10. Governing Law</h2>
            <p>These terms are governed by and construed in accordance with the laws of Insert Jurisdiction without regard to its conflict of law provisions.</p>

            <h2>11. Contact Us</h2>
            <p>If you have any questions about these Terms, please contact us at Insert Contact Information.</p>
        </div>
    );
};

export default TermsOfUse;
